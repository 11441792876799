@import "animate.css";
@import '~loaders.css/src/animations/ball-spin-fade-loader';

.loader-hidden {
  display: none;
}

.loader-active {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  animation: fadeOut 2s 1s;
  animation-fill-mode: forwards;
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.fadeIn {
    animation: fadeIn;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }
}

.app-header {
  text-align: center;
  margin-top: 5%;

  &.fadeIn {
    animation: fadeIn;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }
}

.image {
  position: relative;
  width: 100px;
  height: auto;
  overflow: hidden;
  border-radius: 50%;
  border: 5px solid #000000;
  transition: transform 1s ease-in-out;
  z-index: 2;

  &.rotate {
    transform: rotate(360deg);
  }
}

.lottie-animation {
  position: relative;
  width: 700px;
  height: auto;
  margin-top: -20%;
  z-index: 1;
}

.app-body {
  margin-top: -5%;
  display: flex;
  flex-direction: column;
  width: auto;
}

.nextcloud-btn-container,
.portfolio-btn-container,
.mySocialNetwork-btn-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  border: 2px solid #000000;
  border-radius: 10px;
  white-space: nowrap;
  z-index: 2;
}

.nextcloud-icon,
.portfolio-icon,
.mySocialNetwork-icon {
  width: 50px;
  height: auto;
  margin-left: 1%;
  border-radius: 10px;
}

.tile {
  width: 95%;
}

.app-footer {
  margin-top: 2%;

  &.fadeIn {
    animation: fadeIn;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }
}

.home {
  width: 650px;
  height: auto;
}

h1 {
  margin-left: 15px;
  margin-right: 15px;
}

@media screen and (max-width: 1200px) {
  .image {
    height: 10%;
  }

  .lottie-animation {
    height: 20%;
  }

  .app-body {
    margin-top: -25%;
  }

  .home {
    height: 25%;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }
}
