html {
}

body {
    font: 300 11px/1.4 'Helvetica Neue', sans-serif;
    background: #3498DB;
    height: 100vh;

    display: block;
}

